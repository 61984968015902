<template>
  <!-- 修改企业信息 -->
  <div>
    <!-- form 表单 -->
    <div class="from">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item label="公司名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入公司名称"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业登录账号" prop="account">
          <el-input
            v-model="ruleForm.account"
            placeholder=""
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业登录密码" prop="pwd">
          <el-input
            v-model="ruleForm.pwd"
            show-password
            placeholder=""
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="对应通证">
          <el-select
            v-model="ruleForm.certificateId"
            placeholder="请选择对应通证"
            style="width: 500px"
          >
            <el-option
              v-for="(item, index) in selectBox"
              :key="index"
              :label="item.tokenNum"
              :value="item.certificateId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属行业">
          <el-input
            v-model="ruleForm.trade"
            placeholder="请输入所属行业"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册资本">
          <el-input
            v-model="ruleForm.registeredFund"
            placeholder="请输入注册资本"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input
            v-model="ruleForm.address"
            placeholder="请输入公司地址"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司网站">
          <el-input
            v-model="ruleForm.website"
            placeholder="请输入公司网站"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="归集地址">
          <el-input
            v-model="ruleForm.walletArea"
            placeholder="请输入归集账户钱包地址"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="通知地址">
          <el-input
            v-model="ruleForm.notifyUrl"
            placeholder="请输入通知地址"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司邮箱">
          <el-input
            v-model="ruleForm.email"
            placeholder="请输入公司邮箱"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="人员规模">
          <el-input
            v-model="ruleForm.staffSize"
            placeholder="请输入人员规模"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公众号">
          <el-input
            v-model="ruleForm.officialAccounts"
            placeholder="请输入公众号"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="主营业务">
          <el-input
            type="textarea"
            :rows="4"
            v-model="ruleForm.business"
            placeholder="请输入主营业务"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司介绍">
          <el-input
            type="textarea"
            :rows="4"
            v-model="ruleForm.introduce"
            placeholder="请输入公司介绍"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="getBack">返回</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >修改</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      ruleForm: {},
      rules: {
        name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
      },
      selectBox: "", //选择框
    };
  },
  created() {
    // this.id = this.$route.query.id;
    // this.axios.get("/admin/firm/findById", { id: this.id }).then((res) => {
    //   if (res.data.code == 200) {
    //     this.ruleForm = res.data.data;
    //   }
    // });
    this.ruleForm = this.$route.query.item;

    // 获取通证下拉框
    this.axios.get("/admin/certificate/getDropDownBox", {}).then((res) => {
      console.log(res);
      if (res.data.code == 200) {
        this.selectBox = res.data.data;
      }
    });
  },
  mounted() {},

  methods: {
    //   返回上一页面
    getBack() {
      this.$router.go(-1);
    },
    // 修改
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios
            .put("/admin/firm/updateFirm", this.ruleForm)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.getBack();
              } else {
                this.$message({
                  message: "修改失败",
                  type: "error",
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.from {
  padding: 20px;
}
</style>
